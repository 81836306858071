import _LOGO from "../assets/images/kl_logo.png";

import _FEMALE from "../assets/images/female_large.jpg";
import _MALE from "../assets/images/male_large.jpg";
import _SELECT_USER_PHOTO from "../assets/images/select_user_photo.png";
import _NO_IMAGE from "../assets/images/noimage.png";
import { baseUrl, localSettingsValues } from ".";

export const VENDOR_LOGO = baseUrl + "/public/appfav.png";
export const CONTROLLER_SIGN = baseUrl + "/public/controller_sign.jpg";
export const VENDOR_SECONDARY_LOGO = baseUrl + "/public/secondary_logo.png";

export const LOGO = _LOGO;
export const MALE = _MALE;
export const FEMALE = _FEMALE;
export const SELECT_USER_PHOTO = _SELECT_USER_PHOTO;
export const PAGE_LAYOUT = ["portrait", "landscape"];
export const PAGE_SIZE = ["a4", "a5", "legal"];
export const NO_IMAGE = _NO_IMAGE;

export const DEFAULT_PAGE_LIST_SIZE = "50";

export const printHeader = "";

export const aosInit = {
  offset: 100,
  duration: 600,
  easing: "ease-in-sine",
  delay: 100,
};

export const BLOOD_GROUPS = [
  "A+",
  "B+",
  "A-",
  "B-",
  "B1+",
  "O+",
  "O-",
  "AB+",
  "AB-",
  "A1+",
  "A1-",
  "A2+",
  "A2-",
  "A1B+",
  "A1B-",
  "A2B+",
  "A2B-",
];

export const COURSE_TYPE_SORT_ORDER = localSettingsValues(
  "academic_course_types",
  "ug"
)?.split(",");

export const TABLE_STYLES = {
  tableCollapse: {
    borderCollapse: "collapse",
    border: "none",
  },
  borderBottom: {
    borderCollapse: "collapse",
    borderBottom: "1px solid black",
  },
  borderTop: {
    borderCollapse: "collapse",
    borderTop: "1px solid black",
  },
  borderExceptLeft: {
    borderCollapse: "collapse",
    borderBottom: "1px solid black",
    borderTop: "1px solid black",
    borderRight: "1px solid black",
    padding: "3px",
  },
  borderExceptRight: {
    borderCollapse: "collapse",
    borderBottom: "1px solid black",
    borderTop: "1px solid black",
    borderLeft: "1px solid black",
    padding: "3px",
  },
  borderAll: {
    borderCollapse: "collapse",
    border: "1px solid black",
    padding: "4px",
  },
  borderRight: {
    borderCollapse: "collapse",
    borderRight: "1px solid black",
  },
  borderAllBold: {
    borderCollapse: "collapse",
    border: "1px solid black",
    padding: "4px",
    fontWeight: "bold",
  },
  borderAllNoPadding: {
    borderCollapse: "collapse",
    border: "1px solid black",
    padding: "0px",
  },
  trHideborderAll: {
    display: "none",
    borderCollapse: "collapse",
    border: "1px solid black",
    padding: "4px",
  },
  borderAllHead: {
    borderCollapse: "collapse",
    border: "1px solid black",
    padding: "4px",
    backgroundColor: "#efefef",
    printColorAdjust: "exact",
  },
  borderTopBottom: {
    borderCollapse: "collapse",
    borderTop: "1px solid black",
    borderBottom: "1px solid black",
    padding: "3px",
  },
  borderLeftRight: {
    borderCollapse: "collapse",
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    padding: "3px",
  },
  doubleBorderTop: { borderTop: "1px double black" },
  bgSuccessLight: {
    backgroundColor: "#cfffcf",
  },
  none: {
    textDecoration: "none",
  },
  borderTopBottomDashed: {
    borderCollapse: "collapse",
    borderTop: "1px dashed black",
    borderBottom: "1px dashed black",
    padding: "3px",
  },
  borderTopDashed: {
    borderCollapse: "collapse",
    borderTop: "1px dashed black",

    padding: "3px",
  },
  pageBreak: {
    pageBreakAfter: "always",
  },
};

export const COURSE_MODE = [
  { value: "Offline/Regular", text: "Offline/Regular" },
  { value: "Offline/Part Time", text: "Offline/Part Time" },
  { value: "Online", text: "Online" },
];

export const COURSE_DURATION_TYPE = [
  { value: "years", text: "years" },
  { value: "months", text: "Months" },
];

export const CERTIFICATE_TEMPLATE_NAMES = {
  STUDENT_PROVISIONAL: "student_provisional",
  STUDENT_CONDUCT: "student_conduct",
  STUDENT_MEDIUM: "student_medium",
  STUDENT_VERIFICATION: "student_verification",
  STUDENT_TRANSFER: "student_transfer",
  STUDENT_COMPLETION: "student_program_completion",
  STUDENT_IDCARD: "student_id_card",
  STUDENT_ATTENDANCE: "student_attendance",
  STUDENT_GENUINE: "student_genuine",
};

export const MOB_ICONS = {
  KEY_256: require("../assets/mob/key_256.png"),
  PASSWORD_256: require("../assets/mob/password_256.png"),
  SCREEN_LOCK_256: require("../assets/mob/screen_lock_256.png"),
  USERLOGIN_256: require("../assets/mob/userlogin_256.png"),
  USER_BLACK_256: require("../assets/mob/user_black_256.png"),
  NO_PHOTO_256: require("../assets/mob/no_photo_256.png"),
  NO_PHOTO: require("../assets/mob/transparent.png"),
  NO_USER_512: require("../assets/images/select_user_photo.png"),
};
export const LedgerType = [
  "current-assets",
  "income",
  "equity",
  "expense",
  "current-liabilities",
  "fixed-assets",
];
