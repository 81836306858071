import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { customSorting, groupByMultiple, upperCase } from ".";

const PsCourseListDropdown = (props) => {
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    if (props.dataSource) {
      let m = props.dataSource;
      if (typeof props.dataSource == "string") m = JSON.parse(props.dataSource);
      setDataList(m);
    }
  }, [props.dataSource]);

  const courseItem = (items) => {
    return items.map((item) => {
      return (
        <option
          value={item.course_uuid}
          selected={
            props.value && item.course_uuid == props.value ? "selected" : ""
          }
        >
          {item.degree_name} - {item.course_name} (
          {upperCase(item.coursetype) == "REGULAR"
            ? "R"
            : upperCase(item.coursetype) == "SELF"
            ? "SF"
            : ""}
          )
        </option>
      );
    });
  };

  const loadPrograms = (rawData = false) => {
    let m = customSorting(dataList, ["regular", "self"], "coursetype");
    m = groupByMultiple(m, function (obj) {
      return [obj.coursetype];
    });
    return m.map((items) => (
      <optgroup label={upperCase(items[0].coursetype)}>
        {courseItem(items)}
      </optgroup>
    ));
  };

  const handleChange = (e) => {
    let selCou = [];
    if (e.target.value)
      selCou = dataList.find((item) => item.id == e.target.value);
    console.log(selCou);
    if (props.onChange) props.onChange(e.target.value, selCou);
  };

  return (
    <>
      <Form.Control
        as="select"
        className="fw-bold form-select form-select-md"
        name="course_uuid"
        size={props.size || "sm"}
        onChange={(e) => handleChange(e)}
        style={props.styles || { position: "relative", width: "200px" }}
      >
        <option value="">-Select-</option>
        {loadPrograms()}
      </Form.Control>
    </>
  );
};

export default PsCourseListDropdown;
