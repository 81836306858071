import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";

const PsModalWindow = (props) => {
  const { show, onHide, title, size = "lg" } = props;

  useEffect(() => {
    const handler = (e) => {
      if (
        e.target.closest(
          ".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root"
        ) !== null
      ) {
        e.stopImmediatePropagation();
      }
    };
    document.addEventListener("focusin", handler);
    return () => document.removeEventListener("focusin", handler);
  }, []);

  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        size={size}
        centered={props.centered}
        dialogClassName="law-college-modal"
      >
        <div style={styles.modalWrapper}>
          <Modal.Header closeButton style={styles.header}>
            <h4>{title}</h4>
          </Modal.Header>
          <div style={styles.bodyWrapper}>
            <Modal.Body style={styles.body}>{props.children}</Modal.Body>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

// Inline styles for law college theme with black and golden color scheme
const styles = {
  modalWrapper: {
    border: "3px solid #4B4B4B", // Dark gray border with a slight blackish tone
    padding: "5px", // Padding around the modal
    backgroundColor: "#2C2C2C", // Soft dark background outside the content area
    borderRadius: "10px", // Optional: Slight rounded corners for the entire modal
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow to give depth
  },
  header: {
    backgroundColor: "#6A6A6A", // Darker gray for the header background
    color: "#FFD700", // Golden color for header text
    borderBottom: "5px solid #FFD700", // Golden border at the bottom of the header
    fontFamily: "'Roboto', sans-serif", // Clean, professional font
    textAlign: "center", // Center align the text
    textTransform: "uppercase", // Make header text uppercase
    padding: "15px 20px", // Padding inside the header
    border: "none", // Remove border around header
  },
  bodyWrapper: {
    padding: "0", // No padding in the wrapper
  },
  body: {
    backgroundColor: "#ece4b6",
    color: "#000000",
    fontFamily: "'Open Sans', sans-serif",
    padding: "20px",
    borderTop: "5px solid #FFD700",
    borderRadius: "5px",
  },
};

export default PsModalWindow;
