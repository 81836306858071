import React from "react";
import { Offcanvas } from "react-bootstrap";

const PsOffCanvasWindow = (props) => {
  const { show, onHide, title, size = "sm", placement = "end" } = props;

  return (
    <React.Fragment>
      <Offcanvas
        show={show}
        onHide={onHide}
        backdrop="static"
        className={`offcanvas-${size}-${placement}`}
        placement={placement}
      >
        <div style={styles.modalWrapper}>
          <Offcanvas.Header style={styles.header} closeButton>
            <h4>{title}</h4>
          </Offcanvas.Header>

          <div style={styles.bodyWrapper}>
            <Offcanvas.Body style={styles.body}>
              {props.children}
            </Offcanvas.Body>
          </div>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
};

const styles = {
  modalWrapper: {
    border: "3px solid #4B4B4B", // Dark gray border with a slight blackish tone
    padding: "5px", // Padding around the modal
    backgroundColor: "#2C2C2C", // Soft dark background outside the content area
    borderRadius: "10px", // Optional: Slight rounded corners for the entire modal
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow to give depth
    height: "100%", // Ensure full height for the modal
    display: "flex",
    flexDirection: "column",
  },
  header: {
    backgroundColor: "#6A6A6A", // Darker gray for the header background
    color: "#FFD700", // Golden color for header text
    borderBottom: "5px solid #FFD700", // Golden border at the bottom of the header
    fontFamily: "'Roboto', sans-serif", // Clean, professional font
    textAlign: "center", // Center align the text
    textTransform: "uppercase", // Make header text uppercase
    padding: "15px 20px", // Padding inside the header
    border: "none", // Remove border around header
  },
  bodyWrapper: {
    flex: 1, // Allow body content to grow
    overflowY: "auto", // Enable vertical scrollbar when content exceeds height
    padding: "0", // No padding in the wrapper
  },
  body: {
    backgroundColor: "#ece4b6",
    color: "#000000",
    fontFamily: "'Open Sans', sans-serif",
    padding: "20px",
    borderTop: "5px solid #FFD700",
    borderRadius: "5px",
  },
};

export default PsOffCanvasWindow;
